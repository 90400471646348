<template>
  <div id="app">
    <div id="top">
      <img id="logo" alt="Tithe Calculator logo" src="./assets/tithe-calculator-logo.svg">
    </div>
    <div id="calculator">
      <h1>Tithe Calculator</h1>
      <p>
        <span class="init">Your income:</span> <span class="income">$<input @keypress="isNumber($event)" type="number" id="dollaramount" class="dollaramount" v-model="amount"></span>
        <select id="selection" v-model="selected">
          <option v-for="option in options" v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </p>
      <p class="your-tithe">Your tithe: <span>${{this.percentage}} {{selected}}.</span></p>
      <br>
      <p class="smaller"><a target="_blank" href="https://www.openbible.info/topics/tithes_and_offering">Scriptures on tithing</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: function() {
    return {
      amount: 100,
      selected: 'every week',
      options: [
        { text: 'every week', value: 'every week' },
        { text: 'every other week', value: 'every other week' },
        { text: 'twice a month', value: 'twice a month' },
        { text: 'every month', value: 'every month' }
      ]
    }
  },
  computed: {
    percentage: function() {
      return parseFloat((this.amount / 10))
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
}
</script>

<style>
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
html,
body {
  margin: 0;
  font-size: 16px;
  background: #d8e6e5;
}
a {
  color: #01684c;
}
h1 {
  font-size: 40px;
  text-align: center;
  line-height: 1;
}
p {
  font-size: 32px;
  line-height: 1;
  margin: 0 0 16px 0;
}
p.smaller {
  font-size: 22px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a4846;
  height: 100vh;
  overflow: hidden;
}
#top {
  background: #3E6866;
  height: 70px;
  display: flex;
  justify-content: center;
  padding: 0 16px;
}
#logo {
  max-width: 200px;
  width: 200px;
}
#calculator {
  justify-content: center;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding: 0 22px;
  align-items: center;
}
input {
  font-size: 32px;
  background: #a0c1bf;
  border: 0;
  margin: 10px 0;
  padding: 5px 10px;
  width: 100%;
  color: #294846;
}
span.income {
  width: 100%;
  display: flex;
  align-items: center;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
}
#selection {
  border-radius: 0;
  border: 0;
  background-color: #a0c1bf;
  font-size: 32px;
  padding: 5px 20px 5px 10px;
  width: 100%;
  color: #294846;
}
.your-tithe span {
  display: block;
}
@media screen and (min-width: 813px) {
  h1 {
    font-size: 50px;
  }
  #top {
    justify-content: left;
    height: 100px;
  }
  input {
    width: 150px;
    margin: 0 10px 0 0;
  }
  #selection {
    width: auto;
  }
  span.income {
    width: auto;
    display: initial;
    align-items: center;
  }
  .your-tithe span {
    display: inline;
  }
  #calculator {
    transform: scale(1.2);
  }
  #logo {
    max-width: 300px;
    width: 300px;
  }
}
@media screen and (min-width: 900px) {
  #calculator {
    transform: scale(1.3);
  }
}
@media screen and (min-width: 1000px) {
  #calculator {
    transform: scale(1.5);
  }
}
</style>